import React from "react";
import INULForm from "./pages/in-ul-form";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./app.scss";
import "./_defaults.scss";
import GMOForm from "./pages/gmo-form";
import TelegramForm from "./pages/telegram-form";
import SeekerPortalContext from "./context/seeker-portal-context";
import LanguageRoute from "./components/language-route/language-route";
import AzUzForm from "./pages/az-uz-form";
import GMOAzUzForm from "./pages/gmo-az-uz-form";
import OHForm from "./pages/oh-form";
// import OHCallToAction from "./pages/oh-call-to-action/oh-call-to-action";
import NotificationContext from "./context/notification-context";
import SfSeekerForm from "./pages/sf-seeker-form";
import INConnectForm from "./pages/in-connect-form";
import INSocialForm from "./pages/in-social-form/in-social-form";
import checkPartnerDomain from "./utilities/check-partner-domain";

const App = () => {
  let defaultFormComponent = <INULForm />;
  // check for GHM domains, otherwise use default seeker form
  defaultFormComponent = checkPartnerDomain(defaultFormComponent);

  return (
    <Router>
      <NotificationContext>
        <SeekerPortalContext>
          <Switch>
            <Route path={["/telegram/:id", "/telegram"]}>
              <TelegramForm />
            </Route>
            <LanguageRoute path="/oh-form" component={OHForm} />

            <LanguageRoute
              path="/gmo-az-form"
              component={() => <GMOAzUzForm formType={"AZ"} />}
            />
            <LanguageRoute
              path="/gmo-uz-form"
              component={() => <GMOAzUzForm formType={"UZ"} />}
            />

            {/* Forms in the same country with different brands */}
            <LanguageRoute
              path="/az-forma/umid"
              component={() => <AzUzForm formType={"AZ"} brandName={"umid"} />}
            />
            <LanguageRoute
              path="/uz-forma/masihiy"
              component={() => (
                <AzUzForm formType={"UZ"} brandName={"masihiy"} />
              )}
            />
            <LanguageRoute
              path="/uz-forma/umid"
              component={() => <AzUzForm formType={"UZ"} brandName={"umid"} />}
            />

            {/* Not a typo, forma is a localized route */}
            <LanguageRoute
              path="/az-forma"
              component={() => <AzUzForm formType={"AZ"} />}
            />
            <LanguageRoute
              path="/uz-forma"
              component={() => <AzUzForm formType={"UZ"} />}
            />

            <LanguageRoute path="/in-connect" component={INConnectForm} />
            <LanguageRoute path="/in-social" component={INSocialForm} />

            <LanguageRoute path="/gmo-form" component={GMOForm} />
            <LanguageRoute path="/sf" component={SfSeekerForm} />
            <LanguageRoute component={() => defaultFormComponent} />
          </Switch>
        </SeekerPortalContext>
      </NotificationContext>
    </Router>
  );
};

export default App;
