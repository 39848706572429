import { useEffect, useState } from "react";
import { useNotifications } from "../context/notification-context";
import { useSeekerPortal } from "../context/seeker-portal-context";
import APIService from "../services/api-service";
import formItemMapping from "../utilities/form-items";

const generateDefaultFormData = (fields) => {
  return fields.reduce((data, field) => {
    let fieldObj = formItemMapping[field];
    data[field] = fieldObj.defaultValue;
    return data;
  }, {});
};

const FORM_STATUS = {
  viewed: "viewed",
  started: "started",
  telegram: "telegram",
  submitting: "submitting",
  success: "success",
  error: "error",
};

const useForm = (fields, additionalFormData, partnerName, urlParams) => {
  const [formData, setFormData] = useState(generateDefaultFormData(fields));
  const [formStatus, setFormStatus] = useState(FORM_STATUS.viewed);
  const [submissionResult, setSubmissionResult] = useState(null);

  const { location, loadingLocation, prepareFormSubmission, fixLanguage } =
    useSeekerPortal();
  const { pushNotification } = useNotifications();

  useEffect(() => {
    if (partnerName && !loadingLocation) {
      APIService.registerFormInteraction(
        partnerName,
        location?.countryCode,
        location?.latitude,
        location?.longitude,
        fixLanguage(additionalFormData.language),
        urlParams.utm_campaign
      );
    }
    //eslint-disable-next-line
  }, [partnerName, loadingLocation]);

  useEffect(() => {
    if (location) {
      setFormData({
        ...formData,
        city: location.city,
        state: location.region ?? location.regionCode,
      });
    }
    //eslint-disable-next-line
  }, [location]);

  const updateFormData = (data) => {
    if (formStatus === FORM_STATUS.viewed) {
      APIService.updateFormInteraction("form_started");
      setFormStatus(FORM_STATUS.started);
    }

    setFormData({ ...formData, ...data });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (formData.contactMethod === "Telegram") {
      setFormStatus(FORM_STATUS.telegram);
    } else {
      sendFormSubmission(formData);
    }
  };

  const handleTelegramAuth = (result) => {
    const newFormData = { ...formData, telegramID: result.id };
    setFormData(newFormData);
    sendFormSubmission(newFormData);
  };

  const sendFormSubmission = async (rawFormData) => {
    setFormStatus(FORM_STATUS.submitting);
    let formSubmission = await prepareFormSubmission(
      rawFormData,
      additionalFormData
    );
    APIService.updateFormInteraction("form_completed");

    /**
     * Send seeker to UL Server
     */
    let result = await APIService.submitSeekerForm(formSubmission);
    setFormStatus(result.success ? FORM_STATUS.success : FORM_STATUS.error);
    setSubmissionResult(result);

    if (!result.success) {
      if (result.duplicate) {
        pushNotification(
          "Your form has not been submitted. Check below for details."
        );
      } else {
        pushNotification(
          "An unknown error has occurred and your form was not submitted."
        );
      }
    } else {
      // eslint-disable-next-line no-undef
      wisepops("event", "show-nota-pop-up");
    }
  };

  return {
    formData,
    formStatus,
    setFormStatus,
    updateFormData,
    handleTelegramAuth,
    onFormSubmit,
    submissionResult,
  };
};

export default useForm;
