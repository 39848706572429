import { useEffect, useState } from "react";
import PartnerService from "../services/partner-service";

const usePartner = () => {
  const [partner, setPartner] = useState();

  useEffect(() => {
    getPartnerConfig();
  }, []);

  const getPartnerConfig = () => {
    let partner = PartnerService.loadPartnerData(
      window.location.hostname.split(".")[0]
    );

    //set color scheme
    document
      .getElementById("root")
      .setAttribute("color-scheme", partner.colorScheme);
    document.getElementById("root").classList.add(partner.route);

    setPartner(partner);
  };

  return partner;
};

export default usePartner;
