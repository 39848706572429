import QueryString from "qs";
import { useEffect, useState } from "react";
import useLocalStorage from "./use-local-storage";

const useURLParams = () => {
  const [urlParams, setURLParams] = useState();
  const persistentLocalStorage = useLocalStorage();
  useEffect(() => {
    getURLParams();
    //eslint-disable-next-line
  }, []);

  const getURLParams = () => {
    let queryData = QueryString.parse(window.location.search.substring(1));

    const params = [
      "utm_campaign",
      "campaign",
      "utm_countrycode",
      "countryCode",
    ];

    const rawSubmission = window.location.search.substring(0, 250);
    /** coerce empty string to null so it will not overwrite the value in persistentLocalStorage */
    const submission = rawSubmission === "" ? null : rawSubmission;
    setURLParams({
      ...params.reduce((res, param) => {
        res[param] = getURLParam(param, queryData);
        return res;
      }, {}),
      submission: persistentLocalStorage.set("submission", submission),
    });
  };

  const getURLParam = (name, queryData) => {
    let localStorageValue = localStorage.getItem(name);
    if (localStorageValue && localStorageValue !== "undefined") {
      return localStorage.getItem(name);
    } else {
      let value = queryData[name];
      if (value) {
        localStorage.setItem(name, value);
      }
      return value;
    }
  };

  return urlParams;
};

export default useURLParams;
