import React, { useState } from "react";
import "./in-connect-form.scss";
import { useSeekerPortal } from "../../context/seeker-portal-context";
import FullPageForm from "../../components/full-page-form";
import formItemMapping from "../../utilities/form-items";

const fields = [
  "name",
  "email",
  "phone",
  "acceptedChrist",
  "contactMethod",
  "wantsToConnect",
  "gender",
  "city",
  "state",
  "postal",
];

const INConnectForm = () => {
  const { partner } = useSeekerPortal();
  const [isSecondFormPage, setIsSecondFormPage] = useState(false);
  const checkSubmitDisabled = (formData) => {
    const fieldsToCheck = [
      "name",
      "email",
      "phone",
      "acceptedChrist",
      "contactMethod",
    ];
    for (let field of fieldsToCheck) {
      if (!formData[field]) {
        return true;
      }
    }
    let { wantsToConnect, gender } = formData;
    if (wantsToConnect && isSecondFormPage) {
      if (!checkValidLocationValues(formData) || !gender) {
        return true;
      }
    }
    return false;
  };

  const checkValidLocationValues = (formData) => {
    const fieldsToCheck = ["city", "state", "postal"];
    for (let field of fieldsToCheck) {
      if (
        !formData[field] ||
        !formItemMapping[field].validator(formData[field])
      ) {
        return true;
      }
    }
    return true;
  };

  return (
    <FullPageForm
      fields={fields}
      checkSubmitDisabled={checkSubmitDisabled}
      translationKey="seeker-form"
      logo={partner?.logo}
      toggledFieldsKey="wantsToConnect"
      className="in-connect-form"
      partnerName={partner?.name}
      formType="India Church Connect"
      isSecondFormPage={isSecondFormPage}
      setIsSecondFormPage={setIsSecondFormPage}
      isTwoPages
    />
  );
};

export default INConnectForm;
