import React from "react";
import TranslationService from "../services/translation-service";

export default function withTranslations(WrappedComponent, page, component) {
  return class extends React.Component {
    render() {
      let translations;
      if (component) {
        translations = TranslationService.translations[page][component];
      } else {
        translations = TranslationService.translations[page];
      }
      return <WrappedComponent translations={translations} {...this.props} />;
    }
  };
}
