import FullPageForm from "../../components/full-page-form";
import umidTVLogo from "../../media/umid_logo.png";
import masihiyLogo from "../../media/masihiy_logo.png";
const fields = ["name", "phone", "contactMethod", "gender"];

const AzUzForm = ({ formType, brandName }) => {
  const schemes = {
    AZ: {
      umid: {
        colorScheme: "umid_az",
        logo: umidTVLogo,
        translation: "seeker-form-ghm-az",
      },
      default: {
        colorScheme: "umid_az",
        logo: umidTVLogo,
        translation: "seeker-form-ghm-az",
      },
    },
    UZ: {
      masihiy: {
        colorScheme: "umid_uz",
        logo: masihiyLogo,
        logoWidth: "90px",
        translation: "seeker-form-ghm-uz",
      },
      umid: {
        colorScheme: "umid_az",
        logo: umidTVLogo,
        translation: "seeker-form-ghm-uz-umid",
      },
      default: {
        colorScheme: "umid_uz",
        logo: masihiyLogo,
        logoWidth: "90px",
        translation: "seeker-form-ghm-uz",
      },
    },
  };
  const isCountryMultiBrand = !!brandName;
  const brand = isCountryMultiBrand ? brandName : "default";
  const scheme = schemes[formType][brand];
  const fType = isCountryMultiBrand ? formType + ":" + brandName : formType;

  return (
    <FullPageForm
      fields={fields}
      additionalFormData={{
        acceptedChrist: "Yes",
        wantsToConnect: true,
      }}
      translationKey={scheme.translation}
      logo={scheme?.logo}
      logoWidth={scheme?.logoWidth}
      colorScheme={scheme?.colorScheme}
      alwaysShowToggledFields
      className="az-uz-form"
      formType={fType}
      partnerName="GHM"
      contactMethods={["whatsapp", "telegram", "sms"]}
      showAgeWarning
    />
  );
};

export default AzUzForm;
