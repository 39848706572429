import en from './english.json';
import hi from './hindi.json';
import ta from './tamil.json';
import ru from './russian.json';
import tr from './turkish.json';
import az from './azeri.json';
import uz from './uzbek.json';
import cn from './chinese.json';

const _translations = {
  en,
  hi,
  ta,
  ru,
  tr,
  az,
  uz,
  cn
}

export default _translations;