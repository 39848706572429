/**
 * @typedef {String | null} LocalStorageReturnValue the string value from localstorage, or null if not exists
 * @typedef {String} LocalStorageKey the key of an item in localstorage
 */
export const useLocalStorage = () => {
  /**
   *
   * @param {LocalStorageKey} key the key of an item in localstorage
   * @returns {LocalStorageReturnValue} the string value from localstorage, or null if not exists
   */
  function get(key) {
    const currentLocalStorageValue = localStorage.getItem(key);
    return currentLocalStorageValue;
  }
  /**
   *
   * @param {LocalStorageKey} key the key of an item in localstorage
   * @param {String | null | undefined} value the value to set in localstorage
   * @param {boolean} shouldOverwriteIfNull whether or not to overwrite the value in local storage if null | undefined is passed in as the new value
   * @returns {LocalStorageReturnValue} the value of the specified key in localstorage after this operation completes
   */
  function set(key, value, shouldOverwriteIfNull = false) {
    if (!key) {
      throw new Error("key must be not null!");
    }
    const isNullButShouldOverwrite = !shouldOverwriteIfNull && !value;
    const isNotNull = !!value;
    const shouldSet = isNotNull || isNullButShouldOverwrite;
    if (shouldSet) {
      localStorage.setItem(key, value);
      return value;
    } else return localStorage.getItem(key);
  }

  return { get, set };
};
export default useLocalStorage;
