import FormItemWrapper from "../form-item-wrapper";
import "./form-checkbox.scss";

export default function FormCheckbox({ value, onChange, title, gridArea }) {
  return (
    <FormItemWrapper title={title} gridArea={gridArea} inline valid={value}>
      <div className="form-checkbox">
        <input
          type="checkbox"
          name={gridArea}
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          onKeyDown={(e) => {
            if (e.key === "Enter") onChange(!value);
          }}
        />
        <span className="checkbox__control">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-hidden="true"
            focusable="false"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="3"
              d="M1.73 12.91l6.37 6.37L22.79 4.59"
            />
          </svg>
        </span>
      </div>
    </FormItemWrapper>
  );
}
