import { useEffect, useState } from "react";
import LocationService from "../services/location-service";

const useLocation = () => {
  const [location, setLocation] = useState(null);
  const [loadingLocation, setLoadingLocation] = useState(true);
  const [parentWindowUrl, setParentWindowUrl] = useState(null);

  useEffect(() => {
    getGeoLocation();
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'parentURL') {
        const reformattedUrl = event.data.url.split('://')[1].split('?')[0];
        setParentWindowUrl(reformattedUrl);
      }
    };
    console.log('add listener')
    window.addEventListener('message', handleMessage);
    // Send a message to the parent window to request the parent URL
    window.parent.postMessage('requestParentURL', '*');

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const getGeoLocation = async () => {
    let location = await LocationService.getGeoLocation().catch(() => null);
    setLocation(location);
    setLoadingLocation(false);
  };

  const prepareFormSubmissionLocationData = async (formData) => {
    let submissionLocationData = location;
    if (formData.city !== location.city || formData.state !== (location.region ?? location.regionCode)) {
      submissionLocationData = await LocationService.getNewGeolocation(
        formData.city,
        formData.state
      );
    }
    submissionLocationData.postal = formData.postal;

    return submissionLocationData;
  };

  return { location, loadingLocation, parentWindowUrl, prepareFormSubmissionLocationData };
};

export default useLocation;
