import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import FormItemWrapper from "../form-item-wrapper";
import "./form-select.scss";

export default function FormSelect({
  items,
  value,
  onChange,
  title,
  gridArea,
  required,
}) {
  return (
    <FormItemWrapper
      title={title}
      gridArea={gridArea}
      valid={value !== ""}
      required={required}
    >
      <div className="form-select--items">
        {items.map((item) => {
          let className = `item ${value === item.value ? "active" : ""}`;
          return (
            <Fragment key={item.value}>
              <div
                className={className}
                onClick={() => onChange(item.value)}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") onChange(item.value);
                }}
              >
                {item.icon && (
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="select-icon"
                    size="lg"
                  />
                )}
                {item.label}
                <input
                  style={{ display: "none" }}
                  type="radio"
                  id={item}
                  name={gridArea}
                  value={item}
                  checked={value === item}
                  readOnly
                />
              </div>
            </Fragment>
          );
        })}
      </div>
    </FormItemWrapper>
  );
}
