export default function FormItemWrapper({
  title,
  children,
  gridArea,
  inline,
  valid,
  required,
}) {
  let className = "form-item";
  className += inline ? " inline" : "";
  className += valid ? " valid" : "";

  return (
    <div className={className} style={{ gridArea }}>
      <p className="form-item--title">
        {title} {required ? " *" : ""}
      </p>
      {children}
    </div>
  );
}
