import { createContext, useContext, useState } from "react";
import NotificationList from "../components/notifications/notifications";

//create the context
const notificationContext = createContext();
//create the provider function component that wraps the app
const NotificationContext = (props) => (
  <notificationContext.Provider value={useNotificationContext()}>
    {props.children}
    <NotificationList />
  </notificationContext.Provider>
);

export const useNotifications = () => useContext(notificationContext);

const useNotificationContext = () => {
  let [notifications, setNotifications] = useState([]);

  const pushNotification = (text, action = null) => {
    setNotifications([...notifications, { text, action }]);
  };

  return {
    notifications,
    pushNotification,
    setNotifications,
  };
};

export default NotificationContext;
