import FormCheckbox from "../form/form-checkbox/form-checkbox";
import FormInput from "../form/form-input";
import FormSelect from "../form/form-select";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";

const validators = {
  /**
   * @param {{value:String}} props
   * @returns
   */
  email: ({ value }) =>
    !value ||
    !!value?.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]*[^.\s]+\.[a-zA-Z]{2,4}$/),
  phone: ({ value }) => !value || isValidPhoneNumber(value),
  default: ({ value }) => !value || !(value.length === 0),
};
const FormComponent = ({ formItem, componentProps }) => {
  const {
    inputType,
    field,
    required,
    icon,
    translationField,
    autoFocus,
    selectOptions,
  } = formItem;
  const generateTextInput = ({
    formData,
    duplicateField,
    onFormUpdate,
    translations,
  }) => (
    <FormInput
      validator={validators.default}
      value={formData[field]}
      onChange={(value) => onFormUpdate({ [field]: value })}
      title={translations[translationField]}
      icon={icon}
      required={required}
      isDuplicate={duplicateField === field}
      gridArea={translationField}
      autoFocus={autoFocus}
      invalidErrorMessage={translations["default"]}
    />
  );

  const generateEmailInput = ({
    formData,
    duplicateField,
    onFormUpdate,
    translations,
  }) => (
    <FormInput
      validator={validators.email}
      value={formData[field]}
      onChange={(value) => onFormUpdate({ [field]: value })}
      title={translations[translationField]}
      icon={icon}
      required={required}
      isDuplicate={duplicateField === field}
      gridArea={translationField}
      autoFocus={autoFocus}
      invalidErrorMessage={translations.errors?.["email"]}
      duplicateErrorMessage={translations.errors?.["duplicate-email"]}
    />
  );
  const generatePhoneInput = ({
    formData,
    countryCode,
    duplicateField,
    onFormUpdate,
    translations,
  }) => (
    <FormInput
      validator={validators.phone}
      value={formData[field]}
      onChange={(value) => onFormUpdate({ [field]: value })}
      title={translations[translationField]}
      type="phone"
      required={required}
      countryCode={countryCode}
      isDuplicate={duplicateField === field}
      gridArea={translationField}
      invalidErrorMessage={translations.errors?.["phone"]}
      duplicateErrorMessage={translations.errors?.["duplicate-phone"]}
    />
  );

  const generateSelectInput = ({
    formData,
    onFormUpdate,
    translations,
    selectOptionsOverride,
  }) => {
    let items = selectOptions;
    if (selectOptionsOverride?.[field]) {
      items = selectOptions.filter(({ label }) =>
        selectOptionsOverride[field].includes(label[label.length - 1])
      );
    }

    return (
      <FormSelect
        value={formData[field]}
        validator={validators.default}
        onChange={(value) => onFormUpdate({ [field]: value })}
        title={translations[translationField]?.["label"] || ""}
        gridArea={translationField}
        items={items.map((option) => ({
          ...option,
          label: translations[option.label[0]]?.[option.label[1]] || "",
        }))}
        required={required}
      />
    );
  };

  const generateCheckboxInput = ({ formData, onFormUpdate, translations }) => (
    <FormCheckbox
      validator={validators.default}
      value={!!formData[field]}
      onChange={(value) => onFormUpdate({ [field]: value })}
      title={translations[translationField]}
      gridArea={translationField}
    />
  );
  const generateLocationInput = ({
    formData,
    onFormUpdate,
    translations,
    maxValueLength,
  }) => {
    return (
      <FormInput
        validator={validators.default}
        value={formData[field]}
        onChange={(value) => onFormUpdate({ [field]: value })}
        title={translations["location"]?.[field] || ""}
        icon={icon}
        gridArea={field}
        maxValueLength={maxValueLength}
      />
    );
  };

  switch (inputType) {
    case "text":
      return generateTextInput(componentProps);
    case "email":
      return generateEmailInput(componentProps);
    case "phone":
      return generatePhoneInput(componentProps);
    case "select":
      return generateSelectInput(componentProps);
    case "checkbox":
      return generateCheckboxInput(componentProps);
    case "location":
      return generateLocationInput(componentProps);
    case "postal":
      return generateLocationInput({ maxValueLength: 20, ...componentProps });
    default:
      return null;
  }
};

export default FormComponent;
