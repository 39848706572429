export default class LocationService {
  static GODLIFE_URL =
    "https://godlife.com/api/v1.0/geolocation/?key=BHHBhZAeLf4oN9sc3e5Po5ZAtIbMFsPKcGy1MtXjJ1hTar20uRpZ2LHTymgxy5x";
  static GOOGLE_URL =
    "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBCzfr4MVqkYBM1oAWL6tHf8YjX-bKEgzQ&address=";

  static defaultLocationData = {
    city: "",
    country: "",
    countryCode: "",
    latitude: 0,
    longitude: 0,
    region: "",
    regionCode: "",
  };

  static async getGeoLocation() {
    return new Promise((resolve) => {
      fetch(this.GODLIFE_URL).then(
        (response) => {
          response.json().then((result) => {
            if (result.success) {
              //result.geolocation.countryCode = "CN";
              resolve(result.geolocation);
            }
          });
        },
        (err) => {
          console.error(err);
          resolve(this.defaultLocationData);
        }
      );
    });
  }

  // District and Subdistrict lookup
  static async getNewDistrictGeolocation(subdistrict, district, city) {
    let addressparam = [subdistrict, district, city, "CN"]
      .filter(Boolean)
      .join("+");
    return new Promise((resolve) => {
      fetch(this.GOOGLE_URL + `${addressparam}`).then((response) => {
        response.json().then(({ results, status }) => {
          if (status === "OK" && results.length > 0) {
            let result = results[0];
            let address = result.address_components;
            let location = result.geometry.location;
            let subdistrict = address.filter(
              (item) =>
                item.types.includes("sublocality_level_2") ||
                item.types.includes("route")
            )[0];
            let district = address.filter((item) =>
              item.types.includes("sublocality_level_1")
            )[0];
            resolve({
              city,
              country: address[address.length - 1].long_name,
              countryCode: address[address.length - 1].short_name,
              latitude: location.lat,
              longitude: location.lng,
              region: address[address.length - 2].long_name,
              regionCode: address[address.length - 2].short_name,
              subdistrict: subdistrict ? subdistrict.short_name : "",
              district: district ? district.short_name : "",
            });
          } else {
            resolve(this.defaultLocationData);
          }
        });
      });
    });
  }

  // City State postal lookup
  static async getNewGeolocation(city, state) {
    return new Promise((resolve) => {
      fetch(this.GOOGLE_URL + `${city},${state}`).then((response) => {
        response.json().then(({ results, status }) => {
          if (status === "OK" && results.length > 0) {
            let result = results[0];
            let location = result.geometry.location;
            const country = result?.address_components?.filter((obj) =>
              obj.types.includes("country")
            )[0];
            const region = result?.address_components?.filter((obj) =>
              obj.types.includes("administrative_area_level_1")
            )[0];
            resolve({
              city,
              country: country.long_name,
              countryCode: country.short_name,
              latitude: location.lat,
              longitude: location.lng,
              region: region.long_name,
              regionCode: region.short_name,
            });
          } else {
            resolve(this.defaultLocationData);
          }
        });
      });
    });
  }
}
