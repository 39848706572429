import uniteLifeLogo from "../media/logo_home.png";
import umidTVLogo from "../media/umid_logo.png";

export default class PartnerService {
  static PARTNERS = [
    {
      name: "UniteLife",
      route: null,
      logo: uniteLifeLogo,
      colorScheme: "default",
      contactMethods: [],
      formType: "",
      isTwoPageForm: false,
    },
    {
      name: "UmidTV",
      route: "umid",
      logo: umidTVLogo,
      colorScheme: "umid",
      contactMethods: [],
      formType: "UmidTV",
      isTwoPageForm: false,
    },
    {
      name: "UmidTV",
      route: "aloqa",
      logo: umidTVLogo,
      colorScheme: "umid",
      contactMethods: [],
      formType: "UmidTV",
      isTwoPageForm: false,
    },
    {
      name: "UmidTV",
      route: "elaqe",
      logo: umidTVLogo,
      colorScheme: "umid",
      contactMethods: [],
      formType: "UmidTV",
      isTwoPageForm: false,
    },
    {
      name: "MII",
      route: "mii",
      logo: null,
      colorScheme: "default",
      contactMethods: [],
      formType: "MII",
      isTwoPageForm: false,
    },
    {
      name: "ConnectGrow",
      route: "cgforms",
      logo: null,
      colorScheme: "default",
      contactMethods: [],
      formType: "ConnectGrow",
      isTwoPageForm: false,
    },
    {
      name: "PressBible",
      route: "pb",
      logo: null,
      colorScheme: "default",
      contactMethods: [],
      formType: "PressBible",
      isTwoPageForm: false,
    },
    {
      name: "GMO",
      route: "gmo",
      logo: null,
      colorScheme: "gmo",
      contactMethods: [],
      formType: "GodLife",
      isTwoPageForm: false,
    },
    {
      name: "OneHope",
      route: "oh",
      logo: null,
      colorScheme: "oh",
      contactMethods: [],
      formType: "OneHope",
      isTwoPageForm: false,
    },
    {
      name: "SF",
      route: "sfform",
      logo: null,
      colorScheme: "default",
      contactMethods: [],
      formType: "",
      isTwoPageForm: true,
    },
  ];

  static loadPartnerData(route) {
    let partner = this.PARTNERS.find((partner) => partner.route === route);
    if (!partner) {
      partner = this.PARTNERS[0];
    }

    return partner;
  }
}
