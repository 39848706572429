import { useEffect, useState } from "react";
import { useNotifications } from "../../context/notification-context";
import "./notifications.scss";

const DURATION = 3000;

const NotificationList = () => {
  let { notifications, setNotifications } = useNotifications();

  const closeNotification = (index) => {
    notifications.splice(index, 1);
    setNotifications([...notifications]);
  };

  return (
    <div className="notification-list">
      {notifications.map((notification, i) => (
        <Notification
          {...notification}
          onClose={() => closeNotification(i)}
          key={notification.text}
        />
      ))}
    </div>
  );
};

const Notification = ({ text, action, onClose, duration = DURATION }) => {
  let [remaining, setRemaining] = useState(duration);
  let [hovering, setHovering] = useState(false);

  useEffect(() => {
    const updateProgress = () => setRemaining(remaining - 10);
    if (remaining > 0 && !hovering) {
      setTimeout(updateProgress, 10);
    } else if (remaining <= 0) {
      onClose();
    }
    //eslint-disable-next-line
  }, [remaining, hovering]);

  return (
    <div
      className="notification"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <div className="text-container">
        <p>{text}</p>
        {action && (
          <p
            className="bold action"
            onClick={() => {
              action();
              onClose();
            }}
          >
            View
          </p>
        )}
        <p className="bold action" onClick={onClose}>
          Close
        </p>
      </div>
      <div className="duration">
        <div
          className="remaining"
          style={{ width: `${(remaining / duration) * 100}%` }}
        />
      </div>
    </div>
  );
};

export default NotificationList;
