import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useParams } from "react-router-dom";
import TelegramLoginButton from "react-telegram-login";
import Button from "../../components/button";
import FormInput from "../../components/form/form-input";
import Spinner from "../../components/spinner/spinner";
import APIService from "../../services/api-service";

const BOT_NAME = "ul_manual_connect_bot";

const TelegramForm = () => {
  const { id } = useParams();
  const [telegramResult, setTelegramResult] = useState();
  const [optionalEmailEntry, setOptionalEmailEntry] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState();

  const onLogin = (result) => {
    setTelegramResult(result);
  };

  const onSubmit = async () => {
    const data = {
      salesforceID: id,
      telegramID: telegramResult.id.toString(),
      email: optionalEmailEntry,
    };
    setSubmitting(true);

    let result = await APIService.submitTelegramForm(data).catch(() => false);
    setStatus(result ? "success" : "error");
    setSubmitting(false);
  };

  return (
    <div className="main">
      <div className="telegram-form">
        <div className="form-header">
          <h4>Telegram Authorization</h4>
        </div>
        {status !== "success" && (
          <>
            {!id && (
              <FormInput
                validator={({ value }) => !value || !(value.length === 0)}
                value={optionalEmailEntry}
                onChange={setOptionalEmailEntry}
                title="Email"
                icon={faEnvelope}
                required
                gridArea="email"
              />
            )}
            {!telegramResult ? (
              <TelegramLoginButton dataOnauth={onLogin} botName={BOT_NAME} />
            ) : (
              <p>
                Telegram Account: <b>{telegramResult.username}</b>
              </p>
            )}
            <Button
              text="Submit"
              disabled={(!id && !optionalEmailEntry) || !telegramResult}
              onClick={onSubmit}
            />

            {status === "error" && (
              <p className="error">Unable to authorize telegram.</p>
            )}

            {submitting && (
              <div className="submitting-overlay">
                <Spinner />
              </div>
            )}
          </>
        )}

        {status === "success" && <p>Telegram authorization successful.</p>}
      </div>
    </div>
  );
};

export default TelegramForm;
