import { Redirect, Route } from "react-router-dom";

const LanguageRoute = ({ path = "", component }) => (
  <>
    <Route exact path={`${path}/:lang`} component={component} />
    <Route exact path={path || "/"}>
      <Redirect to={`${path}/en`} />
    </Route>
  </>
);

export default LanguageRoute;
