import QueryString from "qs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useGMOFormData = () => {
  const [additionalFormData, setAdditionalFormData] = useState({});

  const { search } = useLocation();

  useEffect(() => {
    getAdditionalFormData();
    //eslint-disable-next-line
  }, []);

  const getAdditionalFormData = () => {
    let queryData = QueryString.parse(search.substring(1));

    setAdditionalFormData({
      //name, email
      ...queryData,
      acceptedChrist: "Yes",
      wantsToConnect: true,
      is_gmo: true,
      gmo_data: {
        visitor_id: queryData.visitorID,
        identity_id: null,
      },
    });
  };

  return additionalFormData;
};

export default useGMOFormData;
