import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import TranslationService from "../services/translation-service";

const useLanguage = () => {
  const [language, setLanguage] = useState();

  const { lang } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    determinePageLanguage(lang);
    //eslint-disable-next-line
  }, [lang]);

  const determinePageLanguage = (lang) => {
    lang = lang?.toLowerCase();
    if (!TranslationService.languages.find((x) => x.code === lang)) {
      //This won't work for nested pages
      let page = pathname?.split("/")[0] ?? "";
      history.replace(`${page}/en`);
    } else {
      TranslationService.loadTranslations(lang);
      setLanguage(lang);
    }
  };

  return language;
};

export default useLanguage;
