import { useEffect, useState } from "react";
import TranslationService from "../services/translation-service";

const useTranslations = (language, page, component = null) => {
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    if (language) {
      let pageTranslations = TranslationService.translations[page];
      setTranslations(
        component ? pageTranslations[component] : pageTranslations
      );
    }
    //eslint-disable-next-line
  }, [language]);

  return translations;
};

export default useTranslations;
