import { faTelegram, faWhatsapp, faWeixin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faSms } from "@fortawesome/free-solid-svg-icons";

const selectItems = {
  acceptedChrist: [
    { value: "Yes", label: ["accepted-christ", "yes"] },
    { value: "No", label: ["accepted-christ", "no"] },
    {
      value: "Already a Christian",
      label: ["accepted-christ", "already-a-christian"],
    },
  ],
  gender: [
    { value: "Male", label: ["gender", "male"] },
    { value: "Female", label: ["gender", "female"] },
  ],
  contactMethod: [
    {
      value: "WhatsApp",
      label: ["best-contact", "whatsapp"],
      icon: faWhatsapp,
    },
    {
      value: "Telegram",
      label: ["best-contact", "telegram"],
      icon: faTelegram,
    },
    {
      value: "SMS",
      label: ["best-contact", "sms"],
      icon: faSms,
    },
    {
      value: "Email",
      label: ["best-contact", "email"],
      icon: faEnvelope,
    },
    // OneHope requires this contact method but it will be handled as SMS
    // {
    //   value: "WeChat",
    //   label: ["best-contact", "wechat"],
    //   icon: faWeixin,
    // },
  ],
  ageRange: [
    {
      value: "25 and below",
      label: ["age-range", "25-and-below"],
    },
    {
      value: "above 25",
      label: ["age-range", "above-25"],
    },
  ],
};

export default selectItems;
