import React from "react";
import IFrameForm from "../../components/iframe-form";
import useGMOFormData from "../../hooks/use-gmo-form-data";

const fields = ["phone", "contactMethod", "gender"];

const GMOAzUzForm = ({ formType }) => {
  const additionalFormData = useGMOFormData();

  return (
    <IFrameForm
      fields={fields}
      translationKey="seeker-form"
      additionalFormData={additionalFormData}
      className="gmo-form"
      colorScheme="gmo"
      partnerName="GMO"
      formType={`GodLife - ${formType}`}
    />
  );
};

export default GMOAzUzForm;
