import { useEffect, useRef, useState } from "react";
import useLanguage from "../../hooks/use-language";
import useTranslations from "../../hooks/use-translations";
import { OhForm, Form } from "../form";

const IFrameForm = ({
  translationKey,
  additionalFormData,
  className,
  formType,
  isFullscreen = true,
  colorScheme = null,
  hideTitle = true,
  ...props
}) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const sendHeight = () => {
      if (iframeRef.current) {
        const height = iframeRef.current.scrollHeight;
        window.parent.postMessage(height, "*"); 
        // Replace "*" with the domain of the parent window        
      }
    };

    sendHeight();

    window.addEventListener("resize", sendHeight);

    const observer = new MutationObserver(sendHeight);
    if (iframeRef.current) {
      observer.observe(iframeRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener("resize", sendHeight);
      observer.disconnect();
    };
  }, []);
  if (colorScheme) {
    //Need a better solution if we need to expand color scheme functionality
    document.getElementById("root").setAttribute("color-scheme", colorScheme);
  }

  const [{ width, height }, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const language = useLanguage();
  const translations = useTranslations(language, translationKey);

  useEffect(() => {
    window.onresize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div
      className={`iframe-form--background ${isFullscreen && "fullscreen"}`}
      style={{ width }}
      ref={iframeRef}
    >
      {formType === "OneHope" ? (
        <OhForm
          translations={translations}
          additionalFormData={{
            ...additionalFormData,
            language,
            form_type: formType,
          }}
          hideTitle={hideTitle}
          alwaysShowToggledFields
          className={`${className}${
            height > width ? ` ${className}--mobile` : ""
          }`}
          {...props}
        />
      ) : (
        <Form
          translations={translations}
          additionalFormData={{
            ...additionalFormData,
            language,
            form_type: formType,
          }}
          hideTitle={hideTitle}
          alwaysShowToggledFields
          className={`${className}${
            height > width ? ` ${className}--mobile` : ""
          }`}
          {...props}
        />
      )}
    </div>
  );
};

export default IFrameForm;
