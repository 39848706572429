import React from "react";
import { useCallback, useEffect, useState } from "react";
import { useSeekerPortal } from "../../context/seeker-portal-context";
import useForm from "../../hooks/use-form";
import formItemMapping from "../../utilities/form-items";
import Button from "../button";
import Spinner from "../spinner/spinner";
import TelegramLogin from "../telegram-login/telegram-login";
import selectItems from "../../utilities/select-items";
import FormComponent from "../form-component/form-component";
import useURLParams from "../../hooks/use-url-params";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const allContactMethods = selectItems.contactMethod.map(
  ({ label }) => label[label.length - 1]
);

const OhForm = ({
  fields,
  toggledFieldsKey,
  translations,
  checkSubmitDisabled,
  additionalFormData,
  partnerName,
  hideTitle = false,
  alwaysShowToggledFields = false,
  contactMethods: defaultContactMethods = allContactMethods,
  className,
  showAgeWarning,
  isSecondFormPage = false,
  setIsSecondFormPage,
  isTwoPages,
  hideSocialLink = false,
}) => {
  const [contactMethods, setContactMethods] = useState(defaultContactMethods);
  const [formItems, setFormItems] = useState([]);
  const [toggledFormItems, setToggledFormItems] = useState([]);
  const [isTwoPageForm, setIsTwoPageForm] = useState(!!isTwoPages);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const { location, partner } = useSeekerPortal();

  const urlParams = useURLParams();
  useEffect(() => {
    if (fields && formItems.length === 0) {
      let items = [],
        toggledItems = [];
      for (let field of fields) {
        let formItem = formItemMapping[field];
        if (formItem.isToggled) {
          toggledItems.push(formItem);
        } else {
          items.push(formItem);
        }
      }
      setFormItems(items);
      setToggledFormItems(toggledItems);
    }
    //eslint-disable-next-line
  }, [fields]);

  const {
    formData,
    formStatus,
    setFormStatus,
    updateFormData,
    onFormSubmit,
    submissionResult,
    handleTelegramAuth,
  } = useForm(fields, additionalFormData, partnerName, urlParams);

  useEffect(() => {
    if (partner?.contactMethods.length > 0) {
      setContactMethods(
        defaultContactMethods.filter((method) =>
          partner.contactMethods.includes(method)
        )
      );
    }
    if (partner?.isTwoPageForm) {
      setIsTwoPageForm(partner?.isTwoPageForm);
    }
    //eslint-disable-next-line
  }, [partner]);

  const defaultCheckSubmitDisabled = useCallback(
    (formData) => {
      if (checkSubmitDisabled) return checkSubmitDisabled(formData);
      for (let field of fields) {
        if (
          !formData[field] ||
          !formItemMapping[field].validator(formData[field])
        ) {
          return true;
        }
      }
      return false;
    },
    [checkSubmitDisabled, fields]
  );

  useEffect(() => {
    setIsSubmitDisabled(defaultCheckSubmitDisabled(formData));

    if (isSubmitDisabled !== defaultCheckSubmitDisabled(formData)) {
    }
  }, [formData, isSubmitDisabled, defaultCheckSubmitDisabled]);

  const submitForm = (e) => {
    e.preventDefault();
    if (isTwoPageForm && !isSecondFormPage && formData["wantsToConnect"]) {
      setIsSecondFormPage(true);
    } else {
      onFormSubmit(e);
    }
  };

  const formComponentProps = {
    formData,
    onFormUpdate: updateFormData,
    translations,
    duplicateField: submissionResult?.field,
    countryCode: location?.countryCode,
    selectOptionsOverride: {
      contactMethod: contactMethods,
    },
  };

  const checkShowLocation = () => {
    if (isTwoPageForm) {
      if (isSecondFormPage) return true;
    } else if (alwaysShowToggledFields || formData?.[toggledFieldsKey]) {
      return true;
    }
    return false;
  };
  const handleBack = () => setIsSecondFormPage(false);
  const getFacebookLink = (country) => {
    if (country === "in") {
      return "https://www.facebook.com/groups/indiaconnectgroup";
    } else if (country === "ph") {
      return "https://www.facebook.com/groups/2875998599369856";
    } else {
      return "https://www.facebook.com/groups/733401604682994";
    }
  };

  const getTranslationsLinesArray = (translation) => {
    return translation ? translation?.split("\n") : [];
  };

  const dontWantToConnectFields = ["interestedIn", "wantsToConnect"];

  return (
    <>
      <form onSubmit={submitForm} className={`form ${className}`}>
        {!hideTitle && (
          <>
            <div className="form-header">
              {isSecondFormPage && (
                <div className="form-header--back" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
              )}
              <h4 className="form-header--title">
                {getTranslationsLinesArray(translations["title"]).map(
                  (line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />{" "}
                    </React.Fragment>
                  )
                )}
              </h4>

              {/* This conditional was necessary added by the redesign of OH.
                  The form-subtitle is by default located outside the form-header. 
              */}
            </div>

            {!className.includes("oh-form") && (
              <p
                className={`form-subtitle ${
                  submissionResult?.success && "form--hidden"
                }`}
              >
                {translations["subtitle"]}
              </p>
            )}
          </>
        )}

        <div
          className={`${
            formData?.wantsToConnect === true
              ? "form-grid"
              : "form-grid-not-connect"
          } ${isSecondFormPage && "sf-form-grid"} ${
            submissionResult?.success && "form--hidden"
          }`}
        >
          {formData?.wantsToConnect === true && (
            <p
              className={`form-subtitle ${
                submissionResult?.success && "form--hidden"
              }`}
            >
              {translations["subtitle"]}
            </p>
          )}

          {formData?.wantsToConnect === true
            ? formItems.map((formItem) => (
                <FormComponent
                  key={formItem.field}
                  formItem={formItem}
                  componentProps={formComponentProps}
                />
              ))
            : formItems.map(
                (formItem) =>
                  dontWantToConnectFields.includes(formItem.field) && (
                    <FormComponent
                      key={formItem.field}
                      formItem={formItem}
                      componentProps={formComponentProps}
                    />
                  )
              )}
          {checkShowLocation() && formData?.wantsToConnect === true && (
            <div className="toggled-fields">
              {toggledFormItems.map((formItem) => (
                <FormComponent
                  key={formItem.field}
                  formItem={formItem}
                  componentProps={formComponentProps}
                />
              ))}
            </div>
          )}

          <div className="submit">
            <Button
              text={translations["connect-now"]}
              disabled={isSubmitDisabled}
            />
          </div>
        </div>
        {showAgeWarning && (
          <p className="age-warning">{translations["age-warning"]}</p>
        )}
        {formStatus === "submitting" && (
          <div className="submitting-spinner">
            <Spinner />
          </div>
        )}
        {formStatus === "success" && (
          <div className="submission-success-message">
            <h2>{translations["submit"]["title"]}</h2>
            {formData?.wantsToConnect ? (
              <p>{translations["submit"]["subtitle"]}</p>
            ) : (
              <p>{translations["submit-not-connect"]["subtitle"]}</p>
            )}

            {!hideSocialLink && (
              <a href={getFacebookLink(submissionResult?.seeker?.country)}>
                {translations["submit"]["facebook-link"]}
              </a>
            )}
          </div>
        )}
      </form>
      <TelegramLogin
        show={formStatus === "telegram"}
        onClose={() => setFormStatus("started")}
        onResult={handleTelegramAuth}
      />
    </>
  );
};

export default OhForm;
