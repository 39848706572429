import IFrameForm from "../../components/iframe-form";
import formItemMapping from "../../utilities/form-items";
// import CustomFormOH from "../../components/form-custom-oh";

// const fields = ["name", "gender", "phone", "email"];
const fields = [
  "interestedIn",
  "wantsToConnect",
  "name",
  "gender",
  "phone",
  "ageRange",
];

const OHForm = () => {
  const wantsToConnectRequiredFields = [
    "interestedIn",
    "wantsToConnect",
    "name",
    "gender",
    "phone",
    "ageRange",
  ];
  const dontWantToConnectRequiredFields = ["interestedIn"];

  const checkSubmitDisabled = (formData) => {
    const fieldsToCheck = formData?.wantsToConnect
      ? wantsToConnectRequiredFields
      : dontWantToConnectRequiredFields;

    for (let field of fieldsToCheck) {
      if (
        !formData[field] ||
        !formItemMapping[field].validator(formData[field])
      ) {
        return true;
      }
    }
    let { wantsToConnect, gender } = formData;
    if (wantsToConnect && !gender) {
      return true;
    }
    return false;
  };

  return (
    <IFrameForm
      fields={fields}
      checkSubmitDisabled={checkSubmitDisabled}
      additionalFormData={{
        acceptedChrist: "",
        // wantsToConnect: false,
        contactMethod: "sms",
      }}
      translationKey="seeker-form-oh"
      className="oh-form"
      hideTitle={false}
      isFullscreen={false}
      colorScheme="oh"
      contactMethods={["sms"]}
      partnerName="OneHope"
      formType="OneHope"
      hideSocialLink={true}
    />
  );
};

export default OHForm;
