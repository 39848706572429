import React from "react";
import "./in-ul-form.scss";
import { useSeekerPortal } from "../../context/seeker-portal-context";
import FullPageForm from "../../components/full-page-form";
import formItemMapping from "../../utilities/form-items";

const fields = [
  "name",
  "phone",
  "acceptedChrist",
  "contactMethod",
  "wantsToConnect",
  "gender",
  "city",
  "state",
  "postal",
];

const INULForm = () => {
  const { partner, location } = useSeekerPortal();

  const checkSubmitDisabled = (formData) => {
    const fieldsToCheck = [
      "name",
      "phone",
      "acceptedChrist",
      "contactMethod",
    ];
    for (let field of fieldsToCheck) {
      if (
        !formData[field] ||
        !formItemMapping[field].validator(formData[field])
      ) {
        return true;
      }
    }
    let { wantsToConnect, gender } = formData;
    if (!wantsToConnect) {
      if (!checkValidLocationValues(formData) || !gender) {
        return true;
      }
    }
    return false;
  };

  const checkValidLocationValues = (formData) => {
    const fieldsToCheck = ["city", "state", "postal"];
    for (let field of fieldsToCheck) {
      if (!formData[field]) {
        return false;
      }
    }

    return true;
  };

  return (
    <FullPageForm
      fields={fields}
      checkSubmitDisabled={checkSubmitDisabled}
      translationKey="seeker-form"
      logo={partner?.logo}
      toggledFieldsKey="wantsToConnect"
      className="in-ul-form"
      contactMethods={["whatsapp",  "sms"]}
      partnerName={partner?.name}
      formType={partner?.formType || ""}
    />
  );
};

export default INULForm;
