import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormItemWrapper from "../form-item-wrapper";
import "./form-input.scss";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const FormInput = (props) => {
  const {
    value,
    title,
    icon,
    required,
    type,
    countryCode,
    isDuplicate,
    gridArea,
    onChange,
    validator,
    duplicateErrorMessage,
    invalidErrorMessage,
    maxValueLength = 250,
  } = props;
  const [currentErrorMessage, setCurrentErrorMessage] = useState(null);
  const [hasFocus, setHasFocus] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [country, setCountry] = useState(countryCode);
  useEffect(() => {
    const isInvalid = !validator(props) || (country === 'CN' && value?.length !== 14);
    setInvalid(isInvalid);
    if (isInvalid) {
      setCurrentErrorMessage(invalidErrorMessage);
    } else {
      setCurrentErrorMessage(null);
    }
  }, [value, type, props, validator, invalidErrorMessage, country]);
  

  function onInputChange(value) {
    if (value?.length && value.length >= maxValueLength) {
      onChange(value.substring(0, maxValueLength));
    } else {
      onChange(value);
    }
  }
  function onCountryChange(value) {
    setCountry(value);
  }
  useEffect(() => {
    if (hasFocus) {
      setIsTouched(true);
    }
  }, [hasFocus]);

  useEffect(() => {
    if (!isDuplicate && !hasFocus && isTouched && invalid) {
      setCurrentErrorMessage(invalidErrorMessage);
    } else {
      setCurrentErrorMessage(null);
    }
  }, [
    isDuplicate,
    gridArea,
    invalidErrorMessage,
    hasFocus,
    isTouched,
    invalid,
    type,
  ]);

  useEffect(() => {
    if (isDuplicate) {
      setCurrentErrorMessage(duplicateErrorMessage);
    }
  }, [isDuplicate, duplicateErrorMessage]);

  return (
    <FormItemWrapper
      title={title}
      gridArea={gridArea}
      valid={!currentErrorMessage}
      required={required}
    >
      {type !== "phone" && (
        <input
          className="form-input"
          value={value}
          onChange={(e) => onInputChange(e.target.value)}
          autoFocus={title === "Name"}
          onBlur={() => setHasFocus(false)}
          onFocus={() => setHasFocus(true)}
          name={gridArea}
          placeholder={title === "City" ? "Locating..." : ""}
        />
      )}
      {type === "phone" && (
        <PhoneInput
          className="form-input form-input--phone"
          value={value}
          onChange={(val) => onInputChange(val)}
          onCountryChange={(val) => onCountryChange(val)}
          defaultCountry={countryCode}
          onBlur={() => setHasFocus(false)}
          onFocus={() => setHasFocus(true)}
          name="phone"
        />
      )}
      {icon && <FontAwesomeIcon icon={icon} className="icon" />}
      {currentErrorMessage !== null && (
        <p className="error-message">{currentErrorMessage}</p>
      )}
    </FormItemWrapper>
  );
};
export default FormInput;
