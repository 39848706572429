import React, { createContext, useContext } from "react";
import useLocation from "../hooks/use-location";
import usePartner from "../hooks/use-partner";
import useURLParams from "../hooks/use-url-params";

const seekerPortalContext = createContext();
const SeekerPortalContext = (props) => (
  <seekerPortalContext.Provider value={useSeekerPortalContext()}>
    {props.children}
  </seekerPortalContext.Provider>
);
export const useSeekerPortal = () => useContext(seekerPortalContext);

const useSeekerPortalContext = () => {
  const partner = usePartner();
  const { location, loadingLocation, parentWindowUrl, prepareFormSubmissionLocationData } =
    useLocation();
  const urlParams = useURLParams();

  /**
   * Converts language from 'cn' to 'zh', if necessary
   * @param {String} language A language code
   * @returns {String}
   */
  const fixLanguage = (language) => (language === "cn" ? "zh" : language);

  const prepareFormSubmission = async (formData, additionalFormData) => {
    console.log('prepare form submission');
    return {
      ...formData,
      ...additionalFormData,
      language: fixLanguage(additionalFormData.language),
      locationData: await prepareFormSubmissionLocationData(formData),
      urlParamData: urlParams,
      origin: parentWindowUrl ? parentWindowUrl : new URL(window.location.href).hostname,
    };
  };

  return {
    partner,
    location,
    loadingLocation,
    parentWindowUrl,
    urlParams,
    prepareFormSubmission,
    fixLanguage,
  };
};

export default SeekerPortalContext;
