import React from "react";
import "./gmo-form.scss";
import IFrameForm from "../../components/iframe-form";
import useGMOFormData from "../../hooks/use-gmo-form-data";

const fields = ["phone", "contactMethod", "gender", "city", "state", "postal"];

const GMOForm = () => {
  const additionalFormData = useGMOFormData();

  return (
    <IFrameForm
      fields={fields}
      translationKey="seeker-form"
      additionalFormData={additionalFormData}
      className="gmo-form"
      colorScheme="gmo"
      partnerName="GMO"
      formType="GodLife"
    />
  );
};

export default GMOForm;
