import AzUzForm from "../pages/az-uz-form/az-uz-form";

const checkPartnerDomain = (defaultFormComponent) => {
  let defaultPartnerFormComponent = defaultFormComponent;
  let origin = new URL(window.location.href).hostname;
  // check for GHM domains, otherwise use default seeker form
  //aloqa - UZ
  if (origin.includes("aloqa") && origin.includes("masihiy"))
    defaultPartnerFormComponent = (
      <AzUzForm formType={"UZ"} brandName={"masihiy"} />
    );
  if (origin.includes("aloqa") && origin.includes("umid"))
    defaultPartnerFormComponent = (
      <AzUzForm formType={"UZ"} brandName={"umid"} />
    );
  //elaqe - AZ
  if (origin.includes("elaqe") && origin.includes("umid"))
    defaultPartnerFormComponent = <AzUzForm formType={"AZ"} />;

  return defaultPartnerFormComponent;
};

export default checkPartnerDomain;
