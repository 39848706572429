export default class APIService {
  static API = "https://api.pathwayinitiative.com/api/v1/seeker-portal/";
  //static API = "http://localhost:80/api/v1/seeker-portal/";
  static interactionUUID = null;

  static endpoints = {
    form: "interactions",
    submitForm: "submit-form",
    getTelegramBot: "get-telegram-bot",
    submitTelegramForm: "submit-telegram-form",
  };

  static async registerFormInteraction(
    lead_source,
    country,
    latitude,
    longitude,
    language,
    campaign_id
  ) {
    let result = await this.makeRequest("POST", this.endpoints.form, {
      lead_source,
      country,
      latitude,
      longitude,
      language,
      campaign_id,
    });
    this.interactionUUID = result.uuid;
    return result.uuid;
  }

  static async updateFormInteraction(field) {
    if (this.interactionUUID) {
      return this.makeRequest(
        "PUT",
        `${this.endpoints.form}/${this.interactionUUID}`,
        { field }
      );
    }
  }

  static async submitSeekerForm(formData) {

    /* remove email attribute if blank */
    if(!formData.email) delete formData.email;

    /* remove email if contact method is not email to avoid email issues */
    if(formData.email && formData.contactMethod!='Email'){
      delete formData.email;
    }

    let result = await this.makeRequest(
      "POST",
      this.endpoints.submitForm,
      formData
    );
    return result;
  }

  static async getTelegramBotName(origin) {
    let result = await this.makeRequest(
      "GET",
      this.endpoints.getTelegramBot + `?origin=${origin}`
    );
    return result.name;
  }

  static async submitTelegramForm(data) {
    return this.makeRequest("POST", this.endpoints.submitTelegramForm, data);
  }

  /**
   * Makes a request to the API
   * @param {String} method The request method
   * @param {String} path The api path (without url)
   * @param {Object} params Parameters to include with the request
   * @returns {Object} The API's response
   */
  static async makeRequest(method, path, params = null) {
    return new Promise((resolve, reject) => {
      if (method === "GET") {
        let endpoint = this.API + path;
        if (params) {
          let queryString = "?";
          Object.keys(params).forEach((key) => {
            queryString += `${key}=${params[key]}&`;
          });
          endpoint = (endpoint + queryString).slice(0, -1);
        }
        fetch(endpoint).then((response) => {
          if (response.status === 200) {
            response.json().then((result) => {
              resolve(result);
            });
          } else {
            reject(response.status);
          }
        });
      } else {
        fetch(this.API + path, {
          method,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((result) => {
              resolve(result);
            });
          } else {
            reject(response.status);
          }
        });
      }
    });
  }
}
