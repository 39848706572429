import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import TelegramLoginButton from "react-telegram-login";
import APIService from "../../services/api-service";
import withTranslations from "../../utilities/with-translations";
import "./telegram-login.scss";

class TelegramLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      telegramBotName: null,
    };
  }

  componentDidMount() {
    let origin = new URL(window.location.href).hostname;
    APIService.getTelegramBotName(origin).then(
      (telegramBotName) => {
        this.setState({
          telegramBotName,
        });
      },
      (err) => {
        console.error(err);
      }
    );
  }

  render() {
    let { show, onClose, onResult, translations } = this.props;
    let { telegramBotName } = this.state;
    if (!show) return null;

    return (
      <div className="telegram-login">
        <div className="login-wrapper">
          <h4>{translations["title"]}</h4>

          {telegramBotName !== null && (
            <>
              <p>{translations["subtitle"]}</p>
              <TelegramLoginButton
                dataOnauth={onResult}
                botName={telegramBotName}
              />
            </>
          )}
          {telegramBotName === null && <p>{translations["unavailable"]}</p>}
          <FontAwesomeIcon icon={faTimes} className="close" onClick={onClose} />
        </div>
      </div>
    );
  }
}

export default withTranslations(TelegramLogin, "seeker-form", "telegram-login");
